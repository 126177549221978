/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Logo from '../../assets/images/logo_intertennis_white_lowres.png'
// import Logodark from '../../assets/images/logo_intertennis_color_lowres.png'
import FranceFlag from '../../assets/svgs/flag-france.svg'
import GermanyFlag from '../../assets/svgs/flag-germany.svg'
import SpainFlag from '../../assets/svgs/flag-spain.svg'
import EngFlag from '../../assets/svgs/flag-uk.svg'
import { setCookie } from '../../utils/cookies'
import { useTranslation } from 'react-i18next'
import Select, { components } from 'react-select'
import IconOptions from '../iconOptions'
import { Link } from 'react-router-dom'

export const languagePptions = [
    { value: 'en', label: 'EN', icon: EngFlag },
    { value: 'de', label: 'DE', icon: GermanyFlag },
    { value: 'fr', label: 'FR', icon: FranceFlag },
    { value: 'es', label: 'ES', icon: SpainFlag },
]

const Navbar = ({ setShowSignUpPopup }) => {
    const [showNavbar, setShowNavbar] = useState(false)
    const [showNavbarMobile, setShowNavbarMobile] = useState(false)
    const [activeLink, setActiveLink] = useState("/")
    const { i18n } = useTranslation()
    const { t } = useTranslation()
    const [activeLanguage, setActiveLanguage] = useState(languagePptions[0])
    const [language, setLanguage] = useState('en')

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setShowNavbar(true)
            } else if (window.scrollY < 50) {
                setShowNavbar(false)
                setShowNavbarMobile(false)
            } else {
                setShowNavbar(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <div className="flex gap-2 items-center">
                <div className="w-6">
                    <img
                        src={activeLanguage.icon}
                        alt="s-logo"
                        className="object-contain"
                        width={100}
                        height={100}
                    />
                </div>
                <p className="">{children}</p>
            </div>
        </components.SingleValue>
    )

    const handleLangChange = (e) => {
        setCookie('user_language_preference', e.value, 30)
        setActiveLanguage(e)
        var htmlElement = document.querySelector('html')
        setShowNavbarMobile(false);
        htmlElement.lang = e.value
        setLanguage(e.value)
        i18n.changeLanguage(e.value)
    }

    function scrollToSection(id) {
        setActiveLink(id)
        const section = document.getElementById(id)
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' })
        }
    }

    let navLinks = (
        <>
            <li className={activeLink == "/" ? "active-link" : ""}>
                <Link to="/" onClick={() => { setActiveLink("/"); setShowNavbarMobile(false); }}>{t('navbar.home')}</Link>
            </li>
            <li className={activeLink == "/how-to-use" ? "active-link" : ""}>
                <Link to="/how-to-use" onClick={() => { setActiveLink("/how-to-use"); setShowNavbarMobile(false); }}>{t('navbar.how_to_use')}</Link>
            </li>
            <li className={activeLink == "/features" ? "active-link" : ""}>
                <Link to="/features" onClick={() => { setActiveLink("/features"); setShowNavbarMobile(false); }}>{t('navbar.features')}</Link>
            </li>
            <li className={activeLink == "/referral-program " ? "active-link" : ""} style={{ width:"min-content" }} >
                <Link to="/referral-program" onClick={() => { setActiveLink("/referral-program"); setShowNavbarMobile(false); }}>{t('navbar.referral_program')}</Link>
            </li>
            <li className={activeLink == `/blogs/${language}` ? "active-link" : ""} >
                <Link to={`/blogs/${language}`} onClick={() => { setActiveLink("/blogs"); setShowNavbarMobile(false); }}>{t('navbar.blogs')}</Link>
            </li>
            <li className={activeLink == "faqs" ? "active-link" : ""}>
                <a
                    role="button"
                    onClick={() => { scrollToSection('faqs'); setShowNavbarMobile(false); }}
                >
                    {t('navbar.faqs')}
                </a>
            </li>
        </>
    )
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    const menuPlacement = isMobile ? 'top' : 'bottom'
    let SelectLangueage = (
        <Select
            className="language-select z-10"
            defaultValue={{
                label: activeLanguage.label,
                value: activeLanguage.value,
            }}
            options={languagePptions}
            onChange={(e) => {
                handleLangChange(e)
            }}
            placeholder={() => ''}
            styles={{
                singleValue: (base) => ({
                    ...base,
                    display: 'flex',
                    alignItems: 'center',
                }),
            }}
            menuPlacement={menuPlacement}
            components={{
                Option: IconOptions,
                SingleValue,
            }}
        />
    )

    return (
        <>
            <nav
                style={{
                    backdropFilter: 'blur(999px)',
                    background: 'rgba(255,255,255,0.2)',
                }}
                className={`fixed w-full ${showNavbar ? 'shadow-lg top-0' : '-top-24'
                    } transition-all duration-500 ease-in-out z-50 h-20 max-xl:hidden`}
            >
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-3">
                    <a
                        href="#"
                        className="flex items-center space-x-3 rtl:space-x-reverse"
                    >
                        <img
                            src={Logo}
                            className="h-14"
                            alt="intertennise Logo"
                        />
                    </a>
                    <div
                        className={`block w-full md:block md:w-auto`}
                        id="navbar-default"
                    >
                        <ul
                            className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-[1rem] rtl:space-x-reverse md:mt-0 md:border-0 dark:border-gray-700 w-max "
                            id="nav-ul"
                        >
                            {navLinks}
                            {SelectLangueage}
                            <button
                                onClick={() => setShowSignUpPopup(true)}
                                className="block py-2 px-3 glow-yellow"
                                aria-current="page"
                            >
                                <div className="btn-background"></div>
                                {t("navbar.button")}
                            </button>
                        </ul>
                    </div>
                </div>
            </nav>
            <nav
                style={{
                    // backdropFilter: 'blur(15px)',
                    background: 'rgba(81,111,125,1)',
                }}
                className={`fixed w-full ${showNavbar ? 'shadow-lg bottom-0' : '-top-24'
                    } transition-all duration-500 ease-in-out z-50 h-16 xl:hidden`}
            >
                <div className="max-w-screen-xl flex items-center justify-evenly mx-auto mt-2">
                    <div className="w-1/4 ms-3">
                        <Link
                            to="/"
                            className="flex items-center space-x-3 rtl:space-x-reverse"
                        >
                            <img
                                src={Logo}
                                className="h-12"
                                alt="intertennise Logo"
                            />
                        </Link>
                    </div>

                    <div
                        className={`${showNavbarMobile
                            ? 'fixed bottom-0 left-0 transform  -translate-y-16'
                            : 'hidden'
                            } w-full xl:block xl:w-auto`}
                        id="navbar-default"
                    >
                        <ul
                            className="font-medium flex flex-col p-4 xl:p-0 mt-4  border border-gray-100 rounded-lg xl:flex-row xl:space-x-8 rtl:space-x-reverse xl:mt-0 xl:border-0 dark:border-gray-700"
                            id="nav-ul"
                            style={{ background: 'rgba(81,111,125,0.9)' }}
                        >
                            {navLinks}
                            {SelectLangueage}
                        </ul>
                    </div>
                    <div className="w-1/2 flex justify-center">
                        <button
                            onClick={() => setShowSignUpPopup(true)}
                            className="block py-2 px-3 glow-yellow"
                            aria-current="page"
                        >
                            <div className="btn-background"></div>
                            {t("navbar.button")}
                        </button>
                    </div>
                    <div className="w-1/4 flex justify-center">
                        <button
                            onClick={() =>
                                setShowNavbarMobile(!showNavbarMobile)
                            }
                            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg xl:hidden text-white"
                            aria-controls="navbar-default"
                            aria-expanded={showNavbarMobile ? 'true' : 'false'}
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 17 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M1 1h15M1 7h15M1 13h15"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar

